.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: white;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#banner {
    background-image: url(./img/main_bg.jpg);
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center;
    padding: 30px;
}

#banner img {
    background: white;
    border: 1px solid #555;
    width: 130px;
    padding: 10px;
}

#banner h2 {
    color: #3f51b5;
    margin: 0;
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: #3f51b5;
}

#banner p {
    color: black;
    margin-top: 0;
    font-weight: bold;
}

#banner a {
    text-decoration: none;
    color: white;
    border: 1px solid #ccc;
    background-color: #3f51b5;
    padding: 3px;
}

.title {
    text-align: left;
    margin: 0;
    font-weight: bold;
    border-bottom: 3px solid #3f51b5;
}

#wrapper {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
}

@media only screen and (min-width: 600px) {
    #container {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(2, 270px);
    }
}

@media only screen and (max-width: 600px) {
    #container {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: repeat(2, 1fr);
    }
}

.item {
    text-align: justify;
}


.item img {
    width: 60px;
    height: 60px;
}
